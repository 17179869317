import { render, staticRenderFns } from "./Newpage.vue?vue&type=template&id=49c049df&scoped=true&"
import script from "./Newpage.vue?vue&type=script&lang=js&"
export * from "./Newpage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49c049df",
  null
  
)

export default component.exports