<!-- 空页面用于跳转刷新 -->
<template>
  <div class="newpage"></div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    this.$router.go(-1);
    // this.$router.push({
    // 	path:this.$route.query.path
    // })
  },
};
</script>

<style scoped="scoped"></style>
